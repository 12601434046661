import React from 'react';

import { Link, graphql } from 'gatsby';

import PageWrapper from 'Layouts/PageWrapper';
import SEO from 'Components/SEO';
import Img from 'gatsby-image';

export default ({ data }) => {
  const { edges: persons } = data.allMarkdownRemark;

  return (
    <PageWrapper>
      <SEO title="Former members" />
      <main className="page__main page__main--simple">
        <h1 className="page__title">Former Members</h1>
        <div className="team-former__list">
          { persons.map(({ node: team }) => (

            <div className="team-former__card" key={ team.id }>
              <Link to={ team.fields.slug } className="team-former__card__img__link">
                <Img className="team-former__card__img" fixed={ team.frontmatter.image.childImageSharp.fixed } />
              </Link>

              <div className="team-former__card__content">
                <span className="team-former__card__name">
                  { team.frontmatter.name }
                </span>
                <div className="team-former__card__position">
                  <span>Current Position</span>
                  { team.frontmatter.position }
                </div>
                <a className="team-former__card__email" href={ `mailto:${team.frontmatter.email}` }>
                  { team.frontmatter.email }
                </a>
                <div className="team-former__card__phone">{ team.frontmatter.telephone }</div>
                { team.frontmatter.externalLink !== null
                  && <a className="team-former__card__link" href={ team.frontmatter.externalLink } target="_blank" rel="noopener noreferrer">Read More</a> }
              </div>

            </div>
          )) }
        </div>
      </main>
    </PageWrapper>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: {eq: "team"},
          current: {eq: false}
        }
      },
      sort: {
        fields: frontmatter___name,
        order: ASC
      }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            name
            position
            telephone
            email
            current
            externalLink
            image {
              childImageSharp {
                fixed(width: 110, height: 110) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
